$('.header-modal').on("click", ".btn", function () {
	var e = $(this).closest(".forma");
	btn = $(this);
	p_btn = btn.find("p");
	send_button = e.find(".btn");
	form_name = e.find('input[name="name"]').val();
	form_phone_el = e.find('input[name="phone"]');
	form_phone = form_phone_el.val();
	ch = e.find('#choice__p').text();
	message = "Модальное окно\n";
	if (form_phone.length < 18) {
		form_phone_el.addClass("invalid_phone");
		phone = form_phone_el.val(); //проверка
		form_phone_el.val('Введите номер телефона'); 
		form_phone_el.css('color', 'red');
		console.log(form_phone);
		send_button.prop("disabled", true);
		setTimeout(function () {
			form_phone_el.removeClass("invalid_phone");
			form_phone_el.val(phone);
			form_phone_el.css('color', 'black');
			send_button.prop("disabled", false);
		}, 2000)
	}
	else {
		$.ajax({
			url: "api/mail.php",
			type: "post",
			dataType: "json",
			data: {
				phone: form_phone,
				name: form_name,
				ch: ch,
				message: message
			},
			beforeSend: function () {
				p_btn.html("Идёт отправка...")
			},
			success: function () {
				setTimeout(function () {
					p_btn.html("Отправлено! :)");
					send_button.prop("disabled", false);
					e.find('input[name="name"]').val('');
					e.find('input[name="phone"]').val('');
					setTimeout(function () {
						$(".mask").removeClass('show-modal');
						p_btn.html("Отправить");
						$(".thanks").addClass('visible');
					}, 700);
				}, 1200);
			}
		})
	}
});

$('.main').on("click", ".btn", function () {
	var e = $(this).closest(".forma");
	btn = $(this);
	p_btn = btn.find("p");
	send_button = e.find(".btn");
	form_name = e.find('input[name="name"]').val();
	form_phone_el = e.find('input[name="phone"]');
	form_phone = form_phone_el.val();
	from = "Основная форма\n";
	if (form_phone.length < 18) {
		form_phone_el.addClass("invalid_phone");
		phone = form_phone_el.val(); //проверка
		form_phone_el.val('Введите номер телефона');
		form_phone_el.css('color', 'red');
		console.log(form_phone);
		send_button.prop("disabled", true);
		setTimeout(function () {
			form_phone_el.removeClass("invalid_phone");
			form_phone_el.val(phone);
			form_phone_el.css('color', 'black');
			send_button.prop("disabled", false);
		}, 2000)
	}
	else {
		$.ajax({
			url: "api/mail.php",
			type: "post",
			dataType: "json",
			data: {
				phone: form_phone,
				name: form_name,
				message: from
			},
			beforeSend: function () {
				p_btn.html("Идёт отправка...")
			},
			success: function () {
				setTimeout(function () {
					p_btn.text("Отправлено! :)");
					send_button.prop("disabled", false);
					e.find('input[name="name"]').val('');
					e.find('input[name="phone"]').val('');
					setTimeout(function () {
						p_btn.html("Отправить");
					}, 1200);
				}, 1200);
			}
		})
	}
});

$('.middle').on("click", ".btn", function () {
	var e = $(this).closest(".forma");
	btn = $(this);
	p_btn = btn.find("p");
	send_button = e.find(".btn");
	form_name = e.find('input[name="name"]').val();
	form_phone_el = e.find('input[name="phone"]');
	form_phone = form_phone_el.val();
	from = "Форма в центре\n";
	if (form_phone.length < 18) {
		form_phone_el.addClass("invalid_phone");
		phone = form_phone_el.val(); //проверка
		form_phone_el.val('Введите номер телефона');
		form_phone_el.css('color', 'red');
		console.log(form_phone);
		send_button.prop("disabled", true);
		setTimeout(function () {
			form_phone_el.removeClass("invalid_phone");
			form_phone_el.val(phone);
			form_phone_el.css('color', 'black');
			send_button.prop("disabled", false);
		}, 2000)
	}
	else {
		$.ajax({
			url: "api/mail.php",
			type: "post",
			dataType: "json",
			data: {
				phone: form_phone,
				name: form_name,
				message: from
			},
			beforeSend: function () {
				p_btn.html("Идёт отправка...")
			},
			success: function () {
				setTimeout(function () {
					p_btn.text("Отправлено! :)");
					send_button.prop("disabled", false);
					e.find('input[name="name"]').val('');
					e.find('input[name="phone"]').val('');
					setTimeout(function () {
						p_btn.html("Отправить");
					}, 1200);
				}, 1200);
			}
		})
	}
});

$('.footer-feedback').on("click", ".btn", function () {
	var phone;
	var e = $(this).closest(".footer-form");
	btn = $(this);
	p_btn = btn.find("p");
	send_button = e.find(".btn");
	form_name = e.find('input[name="name"]').val();
	form_phone_el = e.find('input[name="phone"]');
	form_phone = form_phone_el.val();
	textarea = e.find('textarea').val();
	from = "Форма в футере\n";
	if (form_phone.length < 18) {
		form_phone_el.addClass("invalid_phone");
		phone = form_phone_el.val(); //проверка
		form_phone_el.val('Введите номер корректно');
		form_phone_el.css('color', 'red');
		console.log(form_phone);
		send_button.prop("disabled", true);
		setTimeout(function () {
			form_phone_el.removeClass("invalid_phone");
			form_phone_el.val(phone);
			form_phone_el.css('color', 'black');
			send_button.prop("disabled", false);
		}, 2000)
	}
	else {
		$.ajax({
			url: "api/mail.php",
			type: "post",
			dataType: "json",
			data: {
				phone: form_phone,
				name: form_name,
				message: from,
				textarea: textarea
			},
			beforeSend: function () {
				p_btn.html("Идёт отправка...")
			},
			success: function () {
				setTimeout(function () {
					p_btn.text("Отправлено! :)");
					send_button.prop("disabled", false);
					e.find('input[name="name"]').val('');
					e.find('input[name="phone"]').val('');
					e.find('textarea').val('');
					setTimeout(function () {
						p_btn.html("Отправить");
					}, 1200);
				}, 1200);
			}
		})
	}
});

function addDiv(size, price) {
	$(".mask").toggleClass("show-modal");
	$(".header-modal__h3 h3").text("Ваш заказ");
	$("#btn p").text("Заказать");
	$('#choice').append('<p id="choice__p">3M Cubitron II 984F: ' + rud + ', ' + size + ' в количестве ' + order + 'шт' + ' за ' + order * price + 'р.</p>', '<p id="choice__order">Заполните форму быстрого заказа и наши менеджеры свяжутся с Вами</p>');
}

for (var i = 1; i < 33; i++) {
	$("tbody").on("click", '#price' + i, function () {
		a = $(this);
		var e = $(this).closest(".table__row");
		price = a.find("p").text();
		size = e.find(".price").text();
		order = e.find('.order span').text();
		rud = a.find('.grub').text();
		addDiv(size, price);
	});
};

$('.thanks').on("click", ".btn", function () {
	$('.thanks').removeClass('visible');
	$('#choice__p').remove();
	$('#choice__order').remove();
	$(".header-modal__h3 h3").html('Заполните форму обратной связи');
	$("#btn p").text("Отправить");
});

$(".header-btn").on("click", function () {
	$(".mask").toggleClass("show-modal");
});

$(".close, .mask").on("click", function () {
	$(".mask").removeClass('show-modal');
	$('#choice__p').remove();
	$('#choice__order').remove();
	$("#btn p").text("Отправить");
	$(".header-modal__h3 h3").html('Заполните форму обратной связи');
});

function closeModal() {
	$(".mask").removeClass("show-modal");
	$('#choice__p').remove();
	$('#choice__order').remove();
	$("#btn p").text("Отправить");
	$(".header-modal__h3 h3").html('Заполните форму обратной связи');
}

$(document).on("keyup", function (e) {
	if (e.keyCode == 27) {
		closeModal();
	}
});

$(document).on("ready", function () {
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 0) {
			$('#scroller').fadeIn();
		} else {
			$('#scroller').fadeOut();
		}
	});
	$('#scroller').on("click", function () {
		$('body,html').animate({
			scrollTop: 0
		}, 700);
		return false;
	});
});

$("a").on("click", function () {
	var elementClick = $(this).attr("href");
	var destination = $(elementClick).offset().top - 150;
	$('html, body').animate({ scrollTop: destination }, 600);
	return false;
});

/* Mask for Phone */
$(document).on("ready", function () {
	[].forEach.call(document.querySelectorAll('input[name="phone"]'), function (input) {
		var keyCode;
		function mask(event) {
			event.keyCode && (keyCode = event.keyCode);
			var pos = this.selectionStart;
			if (pos < 3) event.preventDefault();
			var matrix = "+7 (___) ___-__-__",
				i = 0,
				def = matrix.replace(/\D/g, ""),
				val = this.value.replace(/\D/g, ""),
				new_value = matrix.replace(/[_\d]/g, function (a) {
					return i < val.length ? val.charAt(i++) || def.charAt(i) : a
				});
			i = new_value.indexOf("_");
			if (i != -1) {
				i < 5 && (i = 3);
				new_value = new_value.slice(0, i)
			}
			var reg = matrix.substr(0, this.value.length).replace(/_+/g,
				function (a) {
					return "\\d{1," + a.length + "}"
				}).replace(/[+()]/g, "\\$&");
			reg = new RegExp("^" + reg + "$");
			if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) this.value = new_value;
			if (event.type == "blur" && this.value.length < 5) this.value = ""
		}
		input.addEventListener("input", mask, false);
		input.addEventListener("focus", mask, false);
		input.addEventListener("blur", mask, false);
		// input.addEventListener("keydown", mask, false)
	});
});